import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Country from '../../../../country.json';
import Select from 'react-select';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import NavbarLang from '../../../../../locale/NavbarLang';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loading from '../../../../Loading';
import { apiService } from '../../../../common/apiCallService';

let nationalityOptions = [];
Country.forEach((country) => {
  nationalityOptions.push({
    label: country.name,
    value: country.name,
    image: country.image,
  });
});

const KalonpayPaymentForm = () => {
  const apiUrl = useSelector((state) => state.config.payment_api_url);
  const imageBaseUrl = useSelector((state) => state.config.image_base_url);
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const { jwtData, transactionid, merchantid } = Object.fromEntries(searchParams.entries());
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let decoded = null;
  if (jwtData) {
    decoded = jwt.decode(jwtData);
  }

  const [step1ValidationErrors, setStep1ValidationErrors] = useState({});
  const [step2ValidationErrors, setStep2ValidationErrors] = useState({});
  const [step3ValidationErrors, setStep3ValidationErrors] = useState({});
  const [headerTitle, setHeaderTitle] = useState('Enter your details');
  const [buyCryptoScreenActive, setBuyCryptoScreenActive] = useState(true);
  const [formData, setFormData] = useState({
    billingState: decoded?.userDetails?.billingState || '',
    brand: 'N/A',
    currency: decoded?.currency,
    description: '',
    billingCountry: decoded?.userDetails?.billingCountry ? nationalityOptions.find(option => option?.label === decoded?.userDetails?.billingCountry) : '',
    expirydate: '',
    firstName: decoded?.userDetails?.firstName || '',
    ipAddress: '220.117.201.193',
    lastName: decoded?.userDetails?.lastName || '',
    merchantId: merchantid,
    billingPostcode: decoded?.userDetails?.billingPostcode || '',
    billingCity: decoded?.userDetails?.billingCity || '',
    merchantUserEmail: decoded?.merchantUserEmail || '',
    merchantUserId: decoded?.merchantUserId,
    amount: decoded?.amount,
    merchantRef: decoded?.merchantRef,
    merchantUserMobile: decoded?.userDetails?.phone || '',
    network: decoded?.network,
    pan: '',
    securitycode: '',
    usebrand: 'N/A',
    billingAddress1: decoded?.userDetails?.billingAddress1 || '',
    merchantTransactionId: decoded?.merchantTransactionId,
    transactionId: transactionid,
  });
  const isStepFilled = (step) => {
    if (step === 1) {
      return formData?.firstName && formData?.lastName && formData?.merchantUserEmail && formData?.merchantUserMobile && formData?.billingCountry;
    } else if (step === 2) {
      return formData?.pan && formData?.securitycode && formData?.expirydate;
    } else if (step === 3) {
      return formData?.billingState && formData?.billingCity && formData?.billingAddress1 && formData?.billingPostcode && formData?.billingCountry;
    }
    return false;
  };

  const [currentStep, setCurrentStep] = useState(() => {
    if (isStepFilled(1) && isStepFilled(2) && isStepFilled(3)) {
      return 4;
    } else if (isStepFilled(1) && isStepFilled(2)) {
      return 3;
    } else if (isStepFilled(1)) {
      return 2;
    }
    return 1;
  });

  const decodedAmount = (decoded?.amount / 100)?.toFixed(2);
  const decodedConvertedAmount = (decoded?.convertedAmount).toFixed(2);

  const onInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleStepChange();
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidCardNumber = (cardNumber) => {
    if (cardNumber && cardNumber.length === 22) {
      return isValidLuhnAlgorithm(cardNumber);
    }
    return false;
  };

  const isValidLuhnAlgorithm = (cardNumber) => {
    const sanitizedCardNumber = cardNumber.replace(/\D/g, '');
    if (sanitizedCardNumber.length !== 16) {
      return false;
    }

    let sum = 0,
      doubleUp = false;
    for (let i = sanitizedCardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(sanitizedCardNumber.charAt(i), 10);
      if (doubleUp) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      doubleUp = !doubleUp;
    }
    return sum % 10 === 0;
  };

  useEffect(() => {
    document?.body?.classList?.add('kalonpay-theme');
    return () => {
      document?.body?.classList?.remove('kalonpay-theme');
    };
  }, []);
  useEffect(() => {
    getMerchantSettingDetails();
  }, []);

  const getFiatCurrencySymbol = (fiatCurrency) => {
    switch (fiatCurrency) {
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 'EUR':
        return '€';
      case 'RUB':
        return 'р.';
      case 'JPY':
        return '¥';
      default:
        return '$';
    }
  };

  const validateField = (name, value) => {
    let errors = {};

    switch (name) {
      case 'firstName':
        if (!value) {
          errors.firstName = 'First Name is required';
        }
        break;
      case 'lastName':
        if (!value) {
          errors.lastName = 'Last Name is required';
        }
        break;
      case 'merchantUserEmail':
        if (!value) {
          errors.merchantUserEmail = 'Email is required';
        } else if (!isValidEmail(value)) {
          errors.merchantUserEmail = 'Invalid email format';
        }
        break;
      case 'merchantUserMobile':
        if (!value) {
          errors.merchantUserMobile = 'Mobile Number is required';
        }
        break;
      case 'pan':
        if (!value) {
          errors.pan = 'Card Number is required';
        } else if (!isValidCardNumber(value)) {
          errors.pan = 'Invalid card number';
        }
        break;
      case 'expirydate':
        if (!value) {
          errors.expirydate = 'Expiry date is required';
        }
        break;
      case 'securitycode':
        if (!value) {
          errors.securitycode = 'CVV is required';
        } else if (!/^\d{3,4}$/.test(value)) {
          errors.securitycode = 'Invalid CVV format';
        }
        break;
      case 'billingAddress1':
        if (!value) {
          errors.billingAddress1 = 'Address is required';
        }
        break;
      case 'billingState':
        if (!value) {
          errors.billingState = 'State is required';
        }
        break;
      case 'billingCity':
        if (!value) {
          errors.billingCity = 'City is required';
        }
        break;
      case 'billingPostcode':
        if (!value) {
          errors.billingPostcode = 'Zip code is required';
        }
        break;
      default:
        break;
    }

    return errors;
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errors = validateField(name, value);

    if (errors[name]) {
      if (currentStep === 1) {
        setStep1ValidationErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      } else if (currentStep === 2) {
        setStep2ValidationErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      } else if (currentStep === 3) {
        setStep3ValidationErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      }
    } else {
      if (currentStep === 1) {
        setStep1ValidationErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
      } else if (currentStep === 2) {
        setStep2ValidationErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
      } else if (currentStep === 3) {
        setStep3ValidationErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
      }
    }
  };

  const handleStep1Validation = () => {
    const errors = {};

    if (!formData.firstName) {
      errors.firstName = 'First Name is required';
    }

    if (!formData.lastName) {
      errors.lastName = 'Last Name is required';
    }

    if (!formData.merchantUserEmail) {
      errors.merchantUserEmail = 'Email is required';
    } else if (!isValidEmail(formData.merchantUserEmail)) {
      errors.merchantUserEmail = 'Invalid email format';
    }

    setStep1ValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleStep2Validation = () => {
    const errors = {};

    if (!formData.pan) {
      errors.pan = 'Card Number is required';
    } else if (!isValidCardNumber(formData?.pan)) {
      errors.pan = 'Invalid card number';
    }

    if (!formData.securitycode) {
      errors.securitycode = 'CVV is required';
    } else if (!/^\d{3,4}$/.test(formData.securitycode)) {
      errors.securitycode = 'Invalid CVV format';
    }

    if (!formData.expirydate) {
      errors.expirydate = 'Expiry date is required';
    }

    setStep2ValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleStep3Validation = () => {
    const errors = {};

    if (!formData.billingState) {
      errors.billingState = 'State is required';
    }

    if (!formData.billingCity) {
      errors.billingCity = 'City is required';
    }

    if (!formData.billingAddress1) {
      errors.billingAddress1 = 'Address is required';
    }

    if (!formData.billingPostcode) {
      errors.billingPostcode = 'Zip code is required';
    }

    setStep3ValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleStepChange = () => {
    let nextStep = currentStep;
    if (nextStep === 3 && handleStep3Validation() && !buyCryptoScreenActive) {
      getTransactionDetails();
    }
    while (isStepFilled(nextStep) && nextStep < 4) {
      nextStep += 1;
    }
    if (nextStep === 1 && handleStep1Validation()) {
      setCurrentStep(nextStep + 1);
      setHeaderTitle('Enter card details');
    } else if (nextStep === 2 && handleStep2Validation()) {
      setCurrentStep(nextStep + 1);
      setHeaderTitle('Enter billing address');
    } else if (nextStep === 3 && handleStep3Validation()) {
      setCurrentStep(nextStep + 1);
    } else {
      setCurrentStep(nextStep);
    }
  };
  const getTransactionDetails = async () => {
    try {
      setLoading(true);
      const result = await axios.post(
        `${window.location.hostname === 'localhost' ? apiUrl : ''}/pga/CreateSecurePayment`,
        {
          ...formData,
          billingCountry: formData?.billingCountry.value,
          expirydate: moment(formData?.expirydate).format('MM/YYYY'),
          browser_timezone: new Date().toString().match(/([-\+][0-9]+)\s/)[1],
          browser_color_depth: window?.screen?.colorDepth,
          browser_language: navigator?.language,
          browser_screen_height: window?.innerHeight,
          browser_screen_width: window?.innerWidth,
          os: navigator?.platform,
          browserUserAgent: navigator?.userAgent,
        },
        { headers: { 'Content-Type': 'application/json', 'api-key': decoded?.apiKey } },
      );
      if (result?.data?.transactionURL) {
        window.open(result?.data?.transactionURL, '_self');
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      window.open(`/kalonpay-payment/depositFailed?retryUrl=${window.location.toString()}`, '_self');
    }
  };
  const getMerchantSettingDetails = async () => {
    try {
      await apiService(
        (window?.location?.hostname === 'localhost' ? apiUrl : '') + '/pga/get-merchant-settings-kalonpay',
        {
          merchantId: merchantid,
        },
        (data) => {
          setBuyCryptoScreenActive(data?.settings?.buyCryptoScreenActive);
        },
        (err) => {
          console.error(err);
        },
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const backStep = () => {
    if (currentStep !== 1) {
      setCurrentStep(currentStep - 1);
      if (currentStep === 2) {
        setHeaderTitle('Enter your details');
      }
      if (currentStep === 3) {
        setHeaderTitle('Enter card details');
      }
      if (currentStep === 4) {
        setHeaderTitle('Enter billing address');
      }
    }
  };

  return (
    <>
      {currentStep !== 4 && (
        <div className="kal-payment-container">
          <div className="kal-payment-form-container">
            <div className="kal-form-header">
              <ArrowBackIosIcon onClick={backStep} />
              <p>{t(`${headerTitle}`)}</p>
              <div style={{ display: 'flex', gap: '10px' }}>
                <NavbarLang />
                <CloseIcon />
              </div>
            </div>
            <div className="kal-form-body">
              {currentStep === 1 && (
                <div className={`kal-input `}>
                  <div style={{ paddingBottom: '10px' }}>
                    <div className="kal-lebal" htmlFor={'billingCountry'}>
                      {t('Country of residence')}
                    </div>
                    <Select
                      onChange={(value) => {
                        setFormData({ ...formData, billingCountry: value });
                      }}
                      options={nationalityOptions}
                      value={formData['billingCountry']}
                      formatOptionLabel={formatOptionLabel}
                      placeholder={t('Country of residence')}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '8px',
                        marginBottom: '10px',
                        colors: {
                          ...theme.colors,
                          primary: 'danger',
                        },
                      })}
                    />
                    {step1ValidationErrors?.billingCountry && <p className="kal-validation-error">{t(step1ValidationErrors?.billingCountry)}</p>}
                  </div>
                  <div className="flex-kal-input">
                    <div>
                      <div className="kal-lebal" htmlFor={'firstName'}>
                        {t('First Name')}
                      </div>
                      <input onChange={handleChange} onBlur={handleBlur} name="firstName" value={formData['firstName']} type="text" placeholder={t('First Name')} />
                      {step1ValidationErrors?.firstName && <p className="kal-validation-error">{t(step1ValidationErrors?.firstName)}</p>}
                    </div>
                    <div>
                      <div className="kal-lebal" htmlFor={'lastName'}>
                        {t('Last Name')}
                      </div>
                      <input onChange={handleChange} onBlur={handleBlur} name="lastName" value={formData['lastName']} type="text" placeholder={t('Last Name')} />
                      {step1ValidationErrors?.lastName && <p className="kal-validation-error">{t(step1ValidationErrors?.lastName)}</p>}
                    </div>
                  </div>
                  <div>
                    <div className="kal-lebal" htmlFor={'merchantUserEmail'}>
                      {t('Email')}
                    </div>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-e2e="email-field"
                      name="merchantUserEmail"
                      value={formData['merchantUserEmail']}
                      type="text"
                      placeholder={t('Email')}
                    />
                    {step1ValidationErrors?.merchantUserEmail && <p className="kal-validation-error">{t(step1ValidationErrors?.merchantUserEmail)}</p>}
                  </div>
                  <div>
                    <div className="kal-lebal" htmlFor={'merchantUserMobile'}>
                      {t('Mobile Number')}
                    </div>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="merchantUserMobile"
                      value={formData['merchantUserMobile']}
                      type="text"
                      placeholder={t('Mobile Number')}
                      onKeyDown={onInputKeyDown}
                    />
                    {step1ValidationErrors?.merchantUserMobile && <p className="kal-validation-error">{t(step1ValidationErrors?.merchantUserMobile)}</p>}
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div className="kal-input">
                  <div>
                    <div className="kal-lebal" htmlFor={'pan'}>
                      {t('Card Number')}
                    </div>
                    <input
                      name="pan"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          pan: e.target.value
                            .replace(/\s/g, '')
                            .replace(/\D[^\.]/g, '')
                            .substring(0, 16),
                        })
                      }
                      onBlur={handleBlur}
                      value={getCardNumentMasked(formData['pan'])}
                      type="text"
                      placeholder={t('Card Number')}
                    />
                    {step2ValidationErrors?.pan && <p className="kal-validation-error">{t(step2ValidationErrors?.pan)}</p>}
                  </div>
                  <div className="flex-kal-input">
                    <div>
                      <div className="kal-lebal" htmlFor="expirydate">
                        {t('Expiry Date')}
                      </div>
                      <input type="month" name="expirydate" onChange={handleChange} onBlur={handleBlur} value={formData['expirydate']} placeholder={t('Expiry Date')} />
                      {step2ValidationErrors?.expirydate && <p className="kal-validation-error">{t(step2ValidationErrors?.expirydate)}</p>}
                    </div>
                    <div>
                      <div className="kal-lebal" htmlFor="securitycode">
                        {t('CVV')}
                      </div>
                      <input
                        type="text"
                        value={formData['securitycode']}
                        name="securitycode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="CVV"
                        onKeyDown={onInputKeyDown}
                      />
                      {step2ValidationErrors?.securitycode && <p className="kal-validation-error">{t(step2ValidationErrors?.securitycode)}</p>}
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <div className="kal-input">
                  <div>
                    <div className="kal-lebal" htmlFor={'billingAddress'}>
                      {t('Address')}
                    </div>
                    <input
                      name="billingAddress"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          billingAddress1: e.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      value={formData['billingAddress1']}
                      type="text"
                      placeholder={t('Billing address')}
                    />
                    {step3ValidationErrors?.billingAddress1 && <p className="kal-validation-error">{t(step3ValidationErrors?.billingAddress1)}</p>}
                  </div>
                  <div>
                    <div className="kal-lebal" htmlFor={'billingState'}>
                      {t('State')}
                    </div>
                    <input
                      name="billingState"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          billingState: e.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      value={formData['billingState']}
                      type="text"
                      placeholder={t('State / province / region')}
                    />
                    {step3ValidationErrors?.billingState && <p className="kal-validation-error">{t(step3ValidationErrors?.billingState)}</p>}
                  </div>
                  <div>
                    <div className="kal-lebal" htmlFor={'billingCity'}>
                      {t('City')}
                    </div>
                    <input
                      name="billingCity"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          billingCity: e.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      value={formData['billingCity']}
                      type="text"
                      placeholder={t('city / suburb')}
                    />
                    {step3ValidationErrors?.billingCity && <p className="kal-validation-error">{t(step3ValidationErrors?.billingCity)}</p>}
                  </div>
                  <div>
                    <div className="kal-lebal" htmlFor={'zipCode'}>
                      {t('ZipCode')}
                    </div>
                    <input
                      name="zipCode"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          billingPostcode: e.target.value,
                        })
                      }
                      onBlur={handleBlur}
                      value={formData['zipCode']}
                      type="text"
                      placeholder={t('Zip code')}
                      onKeyDown={onInputKeyDown}
                    />
                    {step3ValidationErrors?.zipCode && <p className="kal-validation-error">{t(step3ValidationErrors?.zipCode)}</p>}
                  </div>

                  <div>
                    <div className="kal-lebal" htmlFor={'billingCountry'}>
                      {t('Billing Country')}
                    </div>
                    <Select
                      onChange={(value) => {
                        setFormData({ ...formData, billingCountry: value });
                      }}
                      options={nationalityOptions}
                      value={formData['billingCountry']}
                      formatOptionLabel={formatOptionLabel}
                      placeholder={t('Billing Country')}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '8px',
                        marginBottom: '10px',
                        colors: {
                          ...theme.colors,
                          primary: 'danger',
                        },
                      })}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Footer */}
            <div className="kal-form-footer">
              <button onClick={handleStepChange} className={loading && 'disabledBtn'} disabled={loading}>
                {loading ? 'loading' : 'Continue'}
              </button>
            </div>
          </div>
        </div>
      )}
      <>
      {currentStep === 4 && buyCryptoScreenActive && (
          <div className="kal-deposit-container">
            <div className="kal-deposit-form-container">
              {/* Header */}
              <div className="kal-deposit-header">
                <ArrowBackIosIcon onClick={backStep} />
                <p>Make a deposit</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <CloseIcon />
                </div>
              </div>
              {/* Body */}
              <div className="kal-deposit-body">
                <img
                  className="deposit-img"
                  src={`${imageBaseUrl}/kalonpay/exchange.svg`}
                  width={75}
                  height={75}
                  alt="maintaince image"
                />
                {/* Onclick img we have dropdown */}

                    {/* review and confirm */}
                    <div className="kal-deposit-review">
                      <p>Please review and confirm your order. Your account will then be credited.</p>
                    </div>
                    {/* Accordian */}
                    <Accordion className="kal-deposit-accordian" defaultExpanded>
                      <AccordionSummary className="kal-deposit-summary" expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        <div className="text-lg">
                          You get <strong className="strong-text">{decodedConvertedAmount} USDT</strong> for{' '}
                          <strong className="strong-text">
                            {getFiatCurrencySymbol(decoded?.currency)}
                            {decodedAmount}
                          </strong>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="kal-deposit-details">
                        <div className="kal-deposit-details-itemlist">
                          <p>
                            {decodedConvertedAmount} USDT @ {getFiatCurrencySymbol(decoded?.currency)}
                            {(decoded?.conversionRate).toFixed(2)}
                          </p>{' '}
                          <p>
                            {getFiatCurrencySymbol(decoded?.currency)}
                            {decodedAmount}
                          </p>
                        </div>
                        <div className="kal-deposit-details-itemlist">
                          <p>Processing Fee</p> <p>Free</p>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* Description */}
                    <div className="kal-deposit-term-description">
                      <p>
                        By clicking confirm you confirm that you agree to KalonPay’s <strong className="strong-text">Terms of Service</strong>, you authorise
                        KalonPay to debit the chosen payment method for the amount above, and you understand this cannot be cancelled, refunded or recalled.
                      </p>
                    </div>
                    {/* Pay Button */}
                    {/* Footer */}
                    <div className="kal-form-footer">
                      <button onClick={getTransactionDetails} className={loading && 'disabledBtn'} disabled={loading}>
                        Pay {getFiatCurrencySymbol(decoded?.currency)} {decodedAmount}
                      </button>
                    </div>
              </div>
            </div>
          </div>
        )}
      </>
      {loading && <Loading/>}
    </>
  );
};

export default KalonpayPaymentForm;

const formatOptionLabel = ({ value, label, image }) => (
  <div style={{ display: 'flex' }}>
    <img src={image} width={18} height={18} />
    <div style={{ marginLeft: '10px', color: 'black' }}>{label}</div>
  </div>
);

const getCardNumentMasked = (val) => {
  var newval = '';
  val = val.replace(/\s/g, '');
  for (var i = 0; i < val.length; i++) {
    if (i % 4 == 0 && i > 0) newval = newval.concat('  ');
    newval = newval.concat(val[i]);
  }
  return newval;
};
