import { SET_IMAGE_BASE_URL } from "../actions/actionTypes";

export default (
  state = {
    // These variable must be use for only local testing perpose
    api_url: "http://localhost:8081",
    payment_api_url: "http://localhost:8080",
    activity_tracking_api_url: "http://localhost:8082",
    deposit_refresh_interval: process.env.DEPOSIT_REFRESH_INTERVAL,
    image_base_url: process.env.IMAGE_BASE_URL+"/images",
    geetest_captcha_id: process.env.GEETEST_CAPTCHA_ID,
    merchantID: process.env.AXCESS_MERCHANT_ID,
    merchantSecret: process.env.AXCESS_SECRET_KEY,
    axcess_hosted_url: process.env.AXCESS_HOSTED_URL,
    TRUST_PAYMENTS_POST_URL: process.env.TRUST_PAYMENTS_POST_URL,
    TRUST_PAYMENTS_REST_API_POST_URL:process.env.TRUST_PAYMENTS_REST_API_POST_URL,
    TRUST_PAYMENTS_LIVE_FLAG_USER_DEPOSIT:process.env.TRUST_PAYMENTS_LIVE_FLAG_USER_DEPOSIT,
    TRUST_PAYMENTS_LIVE_FLAG_B2C:process.env.TRUST_PAYMENTS_LIVE_FLAG_B2C,
    KYC_ACTIVATE:process.env.KYC_ACTIVATE ? process.env.KYC_ACTIVATE : true,
    COIN_SPIRIT_BASE_PATH: process.env.COIN_SPIRIT_BASE_PATH,
    COIN_SPIRIT_HOST_NAME: process.env.COIN_SPIRIT_HOST_NAME,
    COIN_SPIRIT_SUPPORT_MAIL: process.env.COIN_SPIRIT_SUPPORT_MAIL
  },
  action
) => {
  switch (action.type) {
    case SET_IMAGE_BASE_URL:
      return {
        ...state,
        image_base_url: action.payload,
      };
    default:
      return state;
  }
};
