import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';
const imageUrl ='/common/nohistoryicon.svg'
const addImg='/homeb2c/add.png'
const nextImg='/homeb2c/next.png'

const NoAddress = (props) => {
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  useEffect(()=> {
    if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
   }, [])
const image_base_url = useSelector((state) => state.config.image_base_url);
let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div className="BalanceTableMain address-table">
        <div className='address-no-address'>
            <div className='no-address-info-container'>
            <img src={`${image_base_url}${imageUrl}`} alt="noAddress" className='nothing-to-show-dynamic-background'  />
            <p className='nothing-to-display-h'>Nothing to display here</p>
            <p className='save-addresses-you-u'>Save addresses you use regularly to your address book for quick access. Saved addresses do not need to be reverified for use.</p>
          <div className='add-address-btn add-new-address-empty-width' style={{ ...headerButtonStyle }} onClick={() => props.setOpenAddModal(true)}>
                 <img className='carbon-add' src={`${image_base_url}${addImg}`} alt="addAddress"/>
                <span className='add-new-address-btn-text'>Add Address</span>
                <img className='carbon-next rotate-back' src={`${image_base_url}${nextImg}`} alt="next" />
            </div>
                
            </div>
     </div>
    </div>
  );
};

export default NoAddress;