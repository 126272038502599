import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import * as actions from '../../../Redux/actions';
import { connect, useSelector } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import { clearStorageData } from '../../../common/getIP';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';
import { useNavigate } from 'react-router-dom';
import { set2faActivationStatus } from '../../../Redux/actions';

const emailChange = '/common/crytoicons/emailchange.svg';

function ChangeEmail({ open, onClose, setToken }) {
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const [b2cColorMode, setB2CColorMode] = useState(null);
	const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
	const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
	const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
	const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  const navigate = useNavigate();

  const [data, setData] = useState({
    oldEmail: '',
    newEmail: '',
    confirmEmail: '',
  });

  useEffect(()=>{
    if (checkValueInSS("b2cColorMode")) {
			setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
		}
		if (checkValueInSS("headerButtonDarkBgColour")) {
			setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
		}
		if (checkValueInSS("headerButtonLightBgColour")) {
			setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
		}
		if (checkValueInSS("headerDarkTextColour")) {
			setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
		}
		if (checkValueInSS("headerLightTextColour")) {
			setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
		}
  }, [])

  const [showMain, setSetShowMain] = useState(true);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setError('');
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleCloseShowMain = () => setSetShowMain(false);

  let disabled = Object.values(data).some((values) => values.trim() === '' || values.length === 0) || error!=='';

  const handleClose = () => {
    const colorMode = sessionStorage.getItem("colorMode");
    const b2cColorMode = sessionStorage.getItem("b2cColorMode");
    const depositMerchantId = localStorage.getItem("depositMerchantId");
    const depositMethod = localStorage.getItem("depositMethod");
    const transactionId = localStorage.getItem("transactionId");
    const lightFlag = sessionStorage.getItem("lightFlag");
    const activity_tracking_token = localStorage.getItem("activity_tracking_token");
    const activity_tracking_api_url = localStorage.getItem("activity_tracking_api_url");
    const api_url = localStorage.getItem("api_url");
    setToken(null);
    clearStorageData();
    localStorage.setItem("reloadedOnce", "Yes");
    sessionStorage.setItem("colorMode", colorMode);
    sessionStorage.setItem("b2cColorMode", b2cColorMode);
    localStorage.setItem("depositMerchantId", depositMerchantId);
    localStorage.setItem("depositMethod", depositMethod);
    localStorage.setItem("transactionId", transactionId);
    sessionStorage.setItem("lightFlag", lightFlag);
    localStorage.setItem("activity_tracking_token", activity_tracking_token);
    localStorage.setItem("activity_tracking_api_url", activity_tracking_api_url);
    localStorage.setItem("api_url", api_url);
    set2faActivationStatus(false);
    navigate('/home-b2c');
  };

  const handleChangeEmail = () => {
    const user_name = localStorage.getItem("user_name");
    if (data.oldEmail === data.newEmail) {
      setError(`old and new emails must be different.`);
    } else if (data.confirmEmail !== data.newEmail) {
      setError(`Email doesn't matched`);
    }
    else if (data.oldEmail !== user_name) {
      setError('Current email does not match with the records');

    } else {
      apiService(
        (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/b2c-user-change-email-or-password',
        {
          email: localStorage.getItem("user_name"),
          oldEmail: (data.oldEmail).trim(),
          newEmail: (data.newEmail).trim(),
          paymentProviderName:localStorage.getItem('paymentProviderName'),
          domainNameUrl: localStorage.getItem("domainNameUrl")
        },
        (data) => {
          // setLoading(false)
          if (data.msg == 'Email Changed Successfully') {
            handleCloseShowMain();
          }
        },
        (err) => {
          // setLoading(false)
          // setError('Error');
          setError(err?.responseJSON?.msg);
        },
      );
    }

  };

  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div>
      {showMain ? (
        <Dialog onClose={onClose} open={open} className="b2c-signup-model">
          <div className="change-password-modal-header">
            <div className="d-flex justify-content-between ">
              <div className="change-password-heading">Change Email</div>
              <div className="signup-close-icon" data-e2e="close-icon">
                <ClearIcon onClick={onClose} className="icon-close2" />
              </div>
            </div>
          </div>
          <div className="p-4 mobile-height">
            <div>
              <div className="change-Password-input-label">Current Email</div>
              <div className="mt-3">
                <input type="text" className="change-password-input" data-e2e="current-email" placeholder="Enter current email..." name="oldEmail" onChange={(e) => handleChange(e)} />
              </div>
            </div>

            <div>
              <div className="change-Password-input-label mt-4">New Email</div>
              <div className="mt-3">
                <input type="text" className="change-password-input" data-e2e="new-email" placeholder="Enter new email..." name="newEmail" onChange={(e) => handleChange(e)} />
              </div>
            </div>

            <div>
              <div className="change-Password-input-label mt-4">Confirm New Email</div>
              <div className="mt-3">
                <input
                  type="text"
                  className="change-password-input"
                  placeholder=" Confirm new email..."
                  name="confirmEmail"
                  onChange={(e) => handleChange(e)}
                  data-e2e="confirm-email"
                />
              </div>
            </div>
            {error !=='' && 
              <div className="text-danger h6 mt-2 text-center">{error}</div>
            }
            <div className="mt-2">
              <button
                disabled={disabled}
                className={`${disabled ? 'change-password-disabled-button' : 'change-password-enabled-button'} mt-2 `}
                onClick={() => handleChangeEmail()}
                style={{ ...headerButtonStyle }}
                data-e2e="change-btn"
              >
                CHANGE
              </button>
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog onClose={onClose} open={open} className="b2c-signup-model">
          <div className="change-password-modal-header">
            <div className="d-flex justify-content-between ">
              <div className="change-password-heading"></div>
              <div className="signup-close-icon"  data-e2e="close-icon">
                <ClearIcon onClick={onClose} className="icon-close2" />
              </div>
            </div>
          </div>
          <div className="p-4" style={{ width: '463px' }}>
            <div className="text-center">
              <img style={{width:'95px', height:'80px'}} src={`${image_base_url}${emailChange}`} alt="email-logo" width="180px" />
            </div>
            <div className="email-sent-text1 pb-3" style={{paddingTop:'25px'}}>Authorisation Required</div>
            <div className="email-sent-text2 pt-2">{`We’ve sent you a verification link to ${data.newEmail}. Verify your email to change it. Please login again with the new mail id.`}</div>

            <div className="mt-4">
              <button onClick={() => handleClose()} className="change-password-enabled-button mt-2" style={{...headerButtonStyle}}>
                GOT IT
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (value) => dispatch(actions.setLoading(value)),
    setToken: (data) => dispatch(actions.setToken(data)),
  };
};

export default connect(null, mapDispatchToProps)(ChangeEmail);
